
import { computed, defineComponent, onMounted } from "vue"
import { reviewsState, getAndSetReviews } from "@/composables/useReviews"

export default defineComponent({
  name: "ReviewList",
  components: {},
  props: {},
  setup: function () {
    onMounted(() => {
      getAndSetReviews()
    })

    return {
      reviews: computed(() => reviewsState.reviews),
      loading: computed(() => reviewsState.loading),
    }
  },
})
